import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import AuthenticatedRoute from "./authenticatedRoute";

const Home = lazy(() => import("../views/Home"));
const ClientManage = lazy(() => import("../views/ClientManage"));
const Tests = lazy(() => import("../views/Tests"));
const MiFID = lazy(() => import("../views/Tests/MiFID"));
const Settings = lazy(() => import("../views/Settings"));
const NotFound = lazy(() => import("../views/NotFound"));
const AccountError = lazy(() => import("../views/AccountError"));
const AccessDenied = lazy(() => import("../views/AccessDenied"));

const CustomRoutes = () => {
	return (
		<Suspense>
			<Routes>
				<Route path="/" element={<AuthenticatedRoute element={<Home />} />} />
				<Route path="/client" element={<AuthenticatedRoute element={<ClientManage openTab="report" />} />} />
				<Route path="/client/report" element={<AuthenticatedRoute element={<ClientManage openTab="report" />} />} />
				<Route path="/client/contracts" element={<AuthenticatedRoute element={<ClientManage openTab="contracts" />} />} />
				<Route path="/client/orders" element={<AuthenticatedRoute element={<ClientManage openTab="orders" />} />} />
				{/* <Route path="/tests" element={<AuthenticatedRoute element={<Tests />} />} /> */}
				{/* <Route path="/test/mifid" element={<AuthenticatedRoute element={<MiFID />} />} /> */}
				<Route path="/settings" element={<AuthenticatedRoute element={<Settings />} />} />
				<Route path="/account-error" element={<AccountError />} />
				<Route path="/access-denied" element={<AccessDenied />} />
				<Route path="*" element={<AuthenticatedRoute element={<NotFound />} />} />
			</Routes>
		</Suspense>
	);
};

export default CustomRoutes;
