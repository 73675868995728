export type ErrorDto = {
	errorMsg?: string;
};

async function fetchResource(url: string, request: any): Promise<any> {
	// TODO: Logging
	// logger.debug(`API ${request.method}: ${url}`, request.body ?? null);

	// Cache
	// if (request.method === "GET") {
	// 	if (request.cache === true) {
	// 		request.cache = "force-cache";
	// 	} else {
	// 		request.cache = "no-store";
	// 		request.headers.pragma = "no-cache";
	// 		request.headers["cache-control"] = "no-store";
	// 	}
	// }

	try {
		const response = await fetch(url, request);
		// If the user is unauthenticated or unauthorized
		if (response.status === 401 || response.status === 403) {
			//window.location.replace("/unauthorized");
			return {
				errorMsg: "unauthorized"
			};
		} else if (response.status >= 400) {
			// const error = new Error(`Code: ${response.status}, Message: ${response.statusText || "-"}, URL: ${url}`);
			// logEvent(error, "helpers.browser.fetchResource");
			return response ? response.json() : { errorMsg: "Lo sentimos, se ha producido un error" };
		}
		return response ? response.json() : null;
	} catch {
		return { errorMsg: "Se ha producido un error al conectar con el servidor" };
	}
}

export default fetchResource;
