import fetchResource from "./fetch";
import config from "../config";

export type EmbedContent = {
	EmbedToken: string;
	EmbedUrl: string;
	ReportId: string;
};

export type PowerBiReport = {
	id: string;
	name: string;
	webUrl: string;
	embedUrl: string;
	datasetId: string;
};

export type datasetParameters = {
	name: string;
	newValue: string;
};

export const getReportTokens = async (reportId: string) => {
	const url = `${config.powerbi_service_url}/api/powerbi/${encodeURIComponent(reportId)}/tokens?environment=fid`;
	const request = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"x-functions-key": `${config.powerbi_api_reporttokens_code}`
		}
	};

	return fetchResource(url, request);
};
