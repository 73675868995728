import fetchResource from "./fetch";
import config from "../config";

export type AllowedLoginCustomerResponse = {
	allowed: boolean;
	role: string;
	roleId: number;
	message: string;
	customerId: number;
	customerDocumentId: string;
	roleStatus: string;
	accountIssue: boolean;
	typePersonId: number;
};

export const getAllowedLoginCustomers = async (email: string): Promise<AllowedLoginCustomerResponse> => {
	const url = `${config.API_CLIENTS_URL}/api/customer/access/${encodeURIComponent(email)}`;
	const request = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"x-functions-key": `${config.API_CLIENTS_ALLOWEDLOGINCUSTOMERS_CODE}`
		}
	};

	return fetchResource(url, request);
};
