import fetchResource, { ErrorDto } from "./fetch";
import config from "../config";

export type GetOrdersApiType = {
	id: number;
	clientFullName: string;
	productName: string;
	contractCode: string;
	contractCC: string;
	orderTypeId: number;
	orderTypeName: string;
	orderOperationDate: Date | undefined;
	amount: number | undefined;
	shares: number | undefined;
	movedCommittedAmount?: number;
	movedDistributedAmount?: number;
};

export const getOrdersByClient = async (idDocument: string, filterUserContractTypeId: number | undefined) => {
	let url = `${config.API_ORDERS_URL}/api/Orders/client/${idDocument}?code=${config.API_ORDERS_ORDERSBYCLIENT_CODE}`;

	if (filterUserContractTypeId) {
		url = url + `&filterUserContractTypeId=${filterUserContractTypeId}`;
	}

	const request = {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	};

	return fetchResource(url, request);
};

export const getOrdersByProductInContract = async (contractId: number, productId: number): Promise<GetOrdersApiType[] & ErrorDto> => {
	const url = `${config.API_ORDERS_URL}/api/Orders/contract/${contractId}/product/${productId}?code=${config.API_ORDERS_BYPRODUCTCONTRACT_CODE}`;
	const request = {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	};

	return fetchResource(url, request);
};
